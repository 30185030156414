import { Expansion } from '@prisma/client'
import React from 'react'
import GeneralInfo from './expansions/info/GeneralInfo'
import ExpansionInfo from './expansions/info/ExpansionInfo'

interface IProps {
  expansion: string
}

const HomeListingTitle = ({ expansion }: IProps) => {
  const showExpansion = () => {
    switch (expansion) {
      case Expansion.Vanilla:
        return <ExpansionInfo
          title={'Vanilla Private Servers – Classic WoW Servers'}
          description={'A list of Vanilla Private Servers down below. We show all the best Classic Servers available, whether it is blizzlike or customized. '}
          miniDescription={' Sort by release date, recommended, population and more! Or use our helpful filter above.'}
        />
      case Expansion.TBC:
        return <ExpansionInfo
          title={'TBC Private Servers – Best WoW Burning Crusade Servers 2024'}
          description={'Find TBC Private Servers from our server list down below. We are absolutely certain, that there is a 2.4.3 server ready for you!'}
          miniDescription={' Sort by TBC release date, recommended TBC servers, population and more! Or use our helpful filter above.'}
        />
      case Expansion.WOTLK:
        return <ExpansionInfo
          title={'Wotlk Private Servers – Top 3.3.5 WoW Servers in 2024'}
          description={'Explore Wotlk Private Servers through our quality server list. We are confident that you will find a high quality 3.3.5 server, whether it is blizzlike or a funserver!'}
          miniDescription={' Sort by Wotlk population, recommended, release date and more! Or use our helpful filter above.'}
        />
      case Expansion.Cataclysm:
        return <ExpansionInfo
          title={'Cataclysm Private Servers - Best Cataclysm WoW Servers'}
          description={'Discover Cataclysm Private Servers through our database below. We hope you will find a high quality cataclysm server, through our compiled list. '}
          miniDescription={' Sort by recommended Cata WoW servers, population, release date and more! Alternatively, use our advanced filter above.'}
        />
      case Expansion.MOP:
        return <ExpansionInfo
          title={'Mist of Pandaria Private Servers – Best WoW MoP Servers'}
          description={'The best MoP Private Servers can get found down below. That includes blizzlike, fun and custom Mist of Pandaria servers. '}
          miniDescription={' Sort by population, recommended, release date and more! Or use our helpful filter above for finding a MoP server.'}
        />
      case Expansion.WOD:
        return <ExpansionInfo
          title={'WoD Private Servers – All WoW WoD Servers'}
          description={'Currently, there are not many WoD Private Servers but you can find the ones we recommend, down below.'}
          miniDescription={' Sort the World of Draenor servers by population, recommended, release date and more! Or use our helpful filter above.'}
        />
      case Expansion.Legion:
        return <ExpansionInfo
          title={'Legion Private Servers | Top WoW Legion Servers'}
          description={'Legion Private Servers are trending. Find your next favorite one down below. It includes blizzlike, fun, and custom servers.'}
          miniDescription={' Sort the World of Warcraft Legion servers by population, recommended, release date and more! Or use our helpful filter above.'}
        />
      case Expansion.BFA:
        return <ExpansionInfo
          title={'Battle for Azeroth Private Servers – Best BFA Private Servers'}
          description={'From our list down below, you can find BFA Private Servers. Currently, there are several popular ones, with a high population available. '}
          miniDescription={' Sort BFA servers by population, recommended, release date and more! Or use our helpful filter above.'}
        />
      case Expansion.Shadowlands:
        return <ExpansionInfo
          title={'Shadowlands Private Servers | Top Shadowlands WoW Servers'}
          description={'Shadowlands Private Servers are one of the newest expansions by Blizzard. There are already several servers to choose between, with increased rates. '}
          miniDescription={' Sort the Shadowlands servers by population, recommended, release date and more! Or use our helpful filter above.'}
        />
      case Expansion.Dragonflight:
        return <ExpansionInfo
        title={'Dragonflight Private Servers | Top Dragonflight WoW Servers'}
          description={'Dragonflight Private Servers are based on the newest expansion by Blizzard. Currently, there are a low amount of servers for Dragonflight to play on, however several servers are already in development and have announced that they will be releasing it in the near future. We recommend you check the list below for the current Dragonflight servers to play on.'}
          miniDescription={' Sort by population, recommended, release date and more! Or use our helpful filter above.'}
        />
      default:
        return <GeneralInfo
          title={'WoW Private Servers | Best World of Warcraft Private Servers in 2024'}
          description={'Browse our database to find World of Warcraft Private Servers or use our advanced filter above.'}
        />
    }
  }

  return (
    <>
      <div id='home-listing-title' className="home-listing-title">
        {showExpansion()}
      </div>
    </>
  )
}

export default HomeListingTitle
