import React from 'react'
import { Icon } from '@iconify/react'

interface IProps {
  rating: number
  displayRatingNumber?: boolean
}

const StarRatings = ({ rating, displayRatingNumber }: IProps) => {
  const animationTimeout = 5200

  const onStarClick = () => {
    document.getElementById('view-server_reviews')?.scrollIntoView({ behavior: 'smooth' })
    const signInElem = document.getElementById('view-server_reviews_sign-in')
    signInElem?.classList.add('animate', 'animate_bouncein')

    setTimeout(() => {
      signInElem?.classList.remove('animate', 'animate_bouncein')
    }, animationTimeout)

    /* if (!session) {
      toast.warning('You must be logged in to leave a review', {
        theme: 'colored'
      })
    } else {
      // Scroll down to element view
      document.getElementById('review-form')?.scrollIntoView({ behavior: 'smooth' })
    } */
  }

  return (
    <>
      <div className="star-ratings" onClick={() => onStarClick()}>
        {[...Array(5)].map((_, i) => {
          const ratingValue = i + 1
          return (
            <i key={ratingValue} className={'star-ratings_star pe-1 ' + (i < rating ? ' star-ratings_star-blue' : ' star-ratings_star-grey')}>
              <Icon className="zx-transform-scale-13 zx-transition-03" icon="ant-design:star-filled" />
            </i>
          )
        })}
        {displayRatingNumber &&
        <span className="ms-2">({isNaN(rating) ? 0 : rating?.toFixed(2)} out of 5.0)</span>
        }
      </div>
    </>
  )
}

export default StarRatings
