import React, { useMemo, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import BaseButton from './base/BaseButton'

interface HomeFilterProps {
  className?: string
  query: any,
  loading: boolean,
  onSearch: (form: {}) => void,
  setLoading: (loading: boolean) => void
}

const HomeFilter = ({ className, query, loading, onSearch, setLoading } : HomeFilterProps) => {
  // get query and prepopulate the form

  const [form, setForm] = useState({
    title: query.title || '',
    expansion: query.expansion || '',
    population: query.population || '',
    language: query.language || '',
    rates: query.rates || '',
    type: query.type || '',
    status: query.status || '',
    features: query.features || []
  })

  // Cache the query expansion so it doesn't get reset when the user changes the expansion
  const expansionTitle = useMemo(() => query.expansion || query.type, [])

  const [validated, setValidated] = useState(false)
  const [errors] = useState<any>({})

  const handleChange = (e : React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setForm({ ...form, [name]: value })
  }

  const handleFeatures = (e : React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setForm({ ...form, features: [...form.features, e.target.value] })
    } else {
      setForm({ ...form, features: form.features.filter((feature : string) => feature !== e.target.value) })
    }
  }

  const handleSubmit = async (e : React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formTarget = e.currentTarget

    try {
      setLoading(true)

      if (formTarget.checkValidity() === false) {
        e.preventDefault()

        setValidated(true)
        setLoading(false)

        return
      }

      await onSearch(form)

      // Scroll to home-listing-title after search
      const homeListingTitle = document.getElementById('home-listing-title')
      if (homeListingTitle) {
        homeListingTitle.scrollIntoView({ behavior: 'smooth' })
      }
    } catch {
      toast.error('Something went wrong. Please try again later. If the problem persists, please contact us.', {
        theme: 'colored'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
    <div className={'home-filter' + (className ? ` ${className}` : '')}>
      <div className="home-filter_content">
        <div className="home-filter_content_inner">
          <Row className="mb-3">
            <Col md={12}>
              <div className="home-filter_content_text">
                <h2 className="home-filter_content_header">
                  <span className="zx-text-color-yellow me-1 zx-brightness-120 zx-transition-03">Discover the Best</span>
                  <span className="zx-text-color-white"> WoW { expansionTitle } Private Servers below! </span> </h2>
              </div>
            </Col>
          </Row>
          <Form className="home-filter_content_form" noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="outer-container mb-5">
              <Col className="justify-content-center d-flex mt-4 mb-4" md={12}>
                <span className="zx-text-color-white filter-headline fs-2"> FILTERS </span>
              </Col>
              <Col className="mb-4" md={4}>
                <Form.Group controlId="formTitle">
                  <Form.Control
                    className="filter-input"
                    type="text"
                    name="title"
                    placeholder="Search"
                    value={form.title}
                    onChange={handleChange}
                    maxLength={191}
                    minLength={3}
                    isInvalid={errors.title}
                  />
                </Form.Group>
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>
              </Col>
              <Col className="mb-4" md={4}>
                <Form.Group controlId="formExpansion">
                  <Form.Control
                    className="filter-input"
                    as="select"
                    name="expansion"
                    value={form.expansion}
                    onChange={handleChange}
                    isInvalid={errors.expansion}
                  >
                    <option value="" disabled>Expansion</option>
                    <option value="none">None</option>
                    <option value="Vanilla">Vanilla</option>
                    <option value="TBC">TBC</option>
                    <option value="WOTLK">WOTLK</option>
                    <option value="Cataclysm">Cataclysm</option>
                    <option value="MOP">MOP</option>
                    <option value="WOD">WOD</option>
                    <option value="Legion">Legion</option>
                    <option value="BFA">BFA</option>
                    <option value="Shadowlands">Shadowlands</option>
                    <option value="Dragonflight">Dragonflight</option>
                  </Form.Control>
                </Form.Group>
                <Form.Control.Feedback type="invalid">
                  {errors.expansion}
                </Form.Control.Feedback>
              </Col>
              <Col className="mb-4" md={4}>
                <Form.Group controlId="formType">
                  <Form.Control
                    className="filter-input"
                    as="select"
                    name="type"
                    value={form.type}
                    onChange={handleChange}
                    isInvalid={errors.type}
                  >
                    <option value="" disabled>Type</option>
                    <option value="none">None</option>
                    <option value="PVP">PVP</option>
                    <option value="PVE">PVE</option>
                    <option value="RP">RP</option>
                    <option value="Custom">Custom</option>
                  </Form.Control>
                </Form.Group>
                <Form.Control.Feedback type="invalid">
                  {errors.type}
                </Form.Control.Feedback>
              </Col>
              <Col className="mb-4" md={4}>
                <Form.Group controlId="formPopulation">
                  <Form.Control
                    className="filter-input"
                    as="select"
                    name="population"
                    value={form.population}
                    onChange={handleChange}
                    isInvalid={errors.population}
                  >
                    <option value="" disabled>Population</option>
                    <option value="none">None</option>
                    <option value="0-100">0-100</option>
                    <option value="100-500">100-500</option>
                    <option value="500-1000">500-1000</option>
                    <option value="1000-2000">1000-2000</option>
                    <option value="2000-5000">2000-5000</option>
                    <option value="5000+">5000+</option>
                  </Form.Control>
                </Form.Group>
                <Form.Control.Feedback type="invalid">
                  {errors.population}
                </Form.Control.Feedback>
              </Col>
              <Col className="mb-4" md={4}>
                <Form.Group controlId="formStatus">
                  <Form.Control
                    className="filter-input"
                    as="select"
                    name="status"
                    value={form.status}
                    onChange={handleChange}
                    isInvalid={errors.status}
                  >
                  <option value="" disabled>Status</option>
                  <option value="none">None</option>
                  <option value="Alpha">Alpha</option>
                  <option value="Beta">Beta</option>
                  <option value="ComingSoon">Coming Soon</option>
                  <option value="Fresh">Fresh</option>
                  <option value="Released">Released</option>
                  </Form.Control>
                </Form.Group>
                <Form.Control.Feedback type="invalid">
                  {errors.status}
                </Form.Control.Feedback>
              </Col>
              <Col className="mb-4" md={4}>
                <Form.Group controlId="formLanguage">
                  <Form.Control
                    className="filter-input"
                    as="select"
                    name="language"
                    value={form.language}
                    onChange={handleChange}
                    isInvalid={errors.language}
                  >
                    <option value="" disabled>Language</option>
                    <option value="none">None</option>
                    <option value="English">English</option>
                    <option value="Spanish">Spanish</option>
                    <option value="Russian">Russian</option>
                    <option value="German">German</option>
                  </Form.Control>
                </Form.Group>
                <Form.Control.Feedback type="invalid">
                  {errors.language}
                </Form.Control.Feedback>
              </Col>
              <Col className="mb-4" md={4}>
                <Form.Group controlId="formRates">
                  <Form.Control
                    className="filter-input"
                    as="select"
                    name="rates"
                    value={form.rates}
                    onChange={handleChange}
                    isInvalid={errors.rates}
                  >
                    <option value="" disabled>Rates</option>
                    <option value="none">None</option>
                    <option value="1X">1X</option>
                    <option value="2-5X">2-5X</option>
                    <option value="5-10X">5-10X</option>
                    <option value="10-15X">10-15k</option>
                    <option value="15X+">15X+</option>
                    <option value="Instant">Instant</option>
                  </Form.Control>
                </Form.Group>
                <Form.Control.Feedback type="invalid">
                  {errors.rates}
                </Form.Control.Feedback>
              </Col>
              <Col className="mb-4" md={8}>
                <div className="home-filter_content_inner_more-features">
                  <div className="home-filter_content_inner_more-features_title">
                    More Features
                    </div>
                </div>
                <Row>
                  <Col xs={12} md={6} xl={2}>
                    <Form.Check
                      className="filter-input"
                      type="checkbox"
                      name="features"
                      onChange={handleFeatures}
                      label="Blizzlike"
                      value="Blizzlike"
                      id="Blizzlike"
                      checked={form.features.includes('Blizzlike')}
                      isInvalid={!!errors.features}
                    />
                  </Col>
                  <Col xs={12} md={6} xl={3}>
                    <Form.Check
                      className="filter-input"
                      type="checkbox"
                      name="features"
                      onChange={handleFeatures}
                      id="Custom Content"
                      value="Custom Content"
                      label="Custom Content"
                      checked={form.features.includes('Custom Content')}
                      isInvalid={!!errors.features}
                    />
                  </Col>
                  <Col xs={12} md={6} xl={3}>
                    <Form.Check
                      className="filter-input"
                      type="checkbox"
                      name="features"
                      onChange={handleFeatures}
                      id="Increased Rates"
                      value="Increased Rates"
                      label="Increased Rates"
                      checked={form.features.includes('Increased Rates')}
                      isInvalid={!!errors.features}
                    />
                  </Col>
                  <Col xs={12} md={6} xl={4}>
                    <Form.Check
                      className="filter-input"
                      type="checkbox"
                      name="features"
                      id='Instant Endgame'
                      onChange={handleFeatures}
                      value="Instant Endgame"
                      label="Instant Endgame"
                      checked={form.features.includes('Instant Endgame')}
                      isInvalid={!!errors.features}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="justify-content-center d-flex mb-4" md={12}>
                <BaseButton type="submit" className="w-100 home-filter_button zx-btn-blue" loading={loading}>Search</BaseButton>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
    </>
  )
}

export default HomeFilter
