import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
import { withSuperJSONProps as _withSuperJSONProps } from "babel-plugin-superjson-next/tools";
import React, { ReactElement, useEffect, useState } from 'react';
import type { NextPageWithLayout } from '@/pages/_app';
import { Container, Spinner } from 'react-bootstrap';
import HomeListingTitle from '@/components/HomeListingTitle';
import ServerListing from '@/components/server-listing/ServerListing';
import Pagination from '@/components/Pagination';
import HomeFilter from '@/components/HomeFilter';
import HomeAd from '@/components/HomeAd';
import HomeFaq from '@/components/faq/HomeFaq';
import HomeDetailedTextSection from '@/components/HomeDetailedTextSection';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import Router from 'next/router';
import GeneralLayout from '@/layouts/GeneralLayout';
import HomeBanner from '@/components/banners/HomeBanner';
import GeneralNestedLayout from '@/layouts/GeneralNestedLayout';
import { getServers } from '../../api/servers';
import { getBanners } from '../../api/banners';
import api from '../../../api';
import { toast } from 'react-toastify';
import HeroBanner from '@/components/banners/HeroBanner';
import { Expansion } from '@prisma/client';
import { generateSEO } from 'lib/utils/seoHelper';
import Head from 'next/head';
const Page: NextPageWithLayout = ({
  bannersProp,
  serversProp,
  totalPagesProp,
  currentPageProp,
  queryProp,
  internal,
  expansion,
  realmType,
  key,
  host,
  internalUrl,
  faqData
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  // Pass to state, so we can update the state and re-render the component
  const [banners, setBanners] = useState(bannersProp);
  const [servers, setServers] = useState(serversProp);
  const [totalPages, setTotalPages] = useState(totalPagesProp);
  const [currentPage, setCurrentPage] = useState(currentPageProp);
  const [query, setQuery] = useState(queryProp);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setBanners(bannersProp);
    setServers(serversProp);
    setTotalPages(totalPagesProp);
    setCurrentPage(currentPageProp);
    setQuery(queryProp);
  }, [key]);
  const onSearch = async (params: {}) => {
    if (internalUrl) {
      // Remove internal and expansion from query
      // @ts-ignore
      delete params.internal;
    }
    Router.push({
      pathname: internalUrl || '/',
      query: params
    }, undefined, {
      shallow: true
    });
    try {
      const adBanners = await api.banner.getBanners();
      const response = await api.server.getServers(params);
      setBanners(adBanners);
      setServers(response.servers);
      setTotalPages(response.totalPages);
      setCurrentPage(response.currentPage);
      setQuery(response.query);
    } catch {
      toast.error('Something went wrong. Please try again later.');
    }
  };
  const showSEO = () => {
    let expansionShort = '';
    let expansionLong = '';
    if (expansion) {
      switch (expansion) {
        case Expansion.Vanilla:
          expansionShort = 'Classic';
          expansionLong = 'Vanilla';
          break;
        case Expansion.TBC:
          expansionShort = 'TBC';
          expansionLong = 'The Burning Crusade';
          break;
        case Expansion.WOTLK:
          expansionShort = 'WOTLK';
          expansionLong = 'Wrath of the Lich King';
          break;
        case Expansion.Cataclysm:
          expansionShort = 'Cataclysm';
          expansionLong = 'Cataclysm';
          break;
        case Expansion.MOP:
          expansionShort = 'MOP';
          expansionLong = 'Mists of Pandaria';
          break;
        case Expansion.WOD:
          expansionShort = 'WOD';
          expansionLong = 'Warlords of Draenor';
          break;
        case Expansion.Legion:
          expansionShort = 'Legion';
          expansionLong = 'Legion';
          break;
        case Expansion.BFA:
          expansionShort = 'BFA';
          expansionLong = 'Battle for Azeroth';
          break;
        case Expansion.Shadowlands:
          expansionShort = 'Shadowlands';
          expansionLong = 'Shadowlands';
          break;
        case Expansion.Dragonflight:
          expansionShort = 'Dragonflight';
          expansionLong = 'Dragonflight';
          break;
        default:
          break;
      }
    } else if (realmType) {
      switch (realmType) {
        case 'PVE':
          expansionShort = 'PVE';
          expansionLong = 'PVE';
          break;
        case 'PVP':
          expansionShort = 'PVP';
          expansionLong = 'PVP';
          break;
        case 'RP':
          expansionShort = 'RP';
          expansionLong = 'RP';
          break;
        case 'Custom':
          expansionShort = 'Custom';
          expansionLong = 'Custom';
          break;
        default:
          break;
      }
    }
    let title = '';
    let description = '';
    if (expansionShort && expansionLong) {
      title = `${expansionShort} Private Servers - ${expansionLong} WoW Servers - Zremax`;
      description = `${expansionShort} Private Servers can be found through our up to date list of high quality ${expansionLong} WoW Private Servers in 2024. Find servers easily`;
    } else {
      title = 'WoW Private Servers | Best World of Warcraft Private Servers in 2024';
      description = 'WoW Private Servers can get found through our quality list of servers. Filter through the best World of Warcraft Private Servers in 2024.';
    }

    // Estimate reading time in minutes by the number of servers
    const readingTime = Math.ceil(totalPages / 10);
    return generateSEO(title, description, readingTime);
  };
  const buildCannoicalUrl = () => {
    let url = '';
    if (internalUrl) {
      url = `${host}${internalUrl}`;
    } else {
      url = `${host}`;
    }
    return <Head>
        {/** If there's a selected page, set our cannocial url */}
        {currentPage > 1 && <link rel="canonical" href={`${url}?page=${currentPage}`} />}
        {/** Set our next and prev page */}
        {currentPage > 1 && <link rel="prev" href={`${currentPage === 2 ? url : `${url}?page=${currentPage - 1}`}`} />}
        {currentPage < totalPages && <link rel="next" href={`${url}?page=${currentPage + 1}`} />}
        {/** if our selected page is 1 or null, set default canonical url */}
        {(currentPage === 1 || !currentPage) && <link rel="canonical" href={url} />}
      </Head>;
  };
  return <Container>
      {showSEO()}
      {buildCannoicalUrl()}
      <HomeFilter query={query} onSearch={onSearch} loading={loading} setLoading={setLoading} className={!internal || internal && queryProp.home ? 'home-filter-offset' : ''} />
      <HomeAd banners={banners} />
      <HomeListingTitle expansion={expansion} />
      {loading ? <div className="text-center my-5">
            <Spinner animation="border" style={{
        height: '4rem',
        width: '4rem'
      }} />
          </div> : <>
            <ServerListing servers={servers} />
            <Pagination query={query} totalPages={totalPages} currentPage={currentPage} internalUrl={internalUrl} hash='home-listing-title' />
          </>}
      {(realmType && internal && !expansion ? !internal : true) && <>
          <HomeFaq faqData={faqData} category={'Private Servers'} />
          <HomeDetailedTextSection expansion={expansion} />
        </>}
    </Container>;
};
Page.getLayout = function getLayout(page: ReactElement) {
  const {
    props
  } = page;
  let expansionTitle = '';
  if (props.internal) {
    if (props.queryProp.expansion) {
      switch (props.queryProp.expansion) {
        case Expansion.Vanilla:
          expansionTitle = 'Vanilla';
          break;
        case Expansion.TBC:
          expansionTitle = 'The Burning Crusade';
          break;
        case Expansion.WOTLK:
          expansionTitle = 'Wrath of the Lich King';
          break;
        case Expansion.Cataclysm:
          expansionTitle = 'Cataclysm';
          break;
        case Expansion.MOP:
          expansionTitle = 'Mists of Pandaria';
          break;
        case Expansion.WOD:
          expansionTitle = 'Warlords of Draenor';
          break;
        case Expansion.Legion:
          expansionTitle = 'Legion';
          break;
        case Expansion.BFA:
          expansionTitle = 'Battle for Azeroth';
          break;
        case Expansion.Shadowlands:
          expansionTitle = 'Shadowlands';
          break;
        case Expansion.Dragonflight:
          expansionTitle = 'Dragonflight';
          break;
      }
    } else if (props.queryProp.type) {
      switch (props.queryProp.type) {
        case 'PVP':
          expansionTitle = 'PvP';
          break;
        case 'PVE':
          expansionTitle = 'PvE';
          break;
        case 'RP':
          expansionTitle = 'RP';
          break;
        case 'Custom':
          expansionTitle = 'Custom';
          break;
      }
    }
  }
  return <GeneralLayout>
      {!props.internal || props.internal && props.queryProp.home ? <HomeBanner /> : <HeroBanner firstTitle={expansionTitle} secondTitle="Private Servers" imageUrl="/images/banners/expansions-banner.png" />}
      <GeneralNestedLayout>
        {page}
      </GeneralNestedLayout>
    </GeneralLayout>;
};
export const getServerSideProps: GetServerSideProps = _withSuperJSONProps(async ({
  query
}) => {
  const data = await getServers(query);
  const banners = await getBanners();
  let internalUrl = '';

  // FAQ related
  const faqData = {
    expansionFaq: '',
    result: [{
      question: '',
      answer: ''
    }, {
      question: '',
      answer: ''
    }, {
      question: '',
      answer: ''
    }, {
      question: '',
      answer: ''
    }]
  };
  if (query.internal) {
    if (query.expansion) {
      switch (query.expansion) {
        case Expansion.Vanilla:
          internalUrl = '/wow/private-servers/vanilla-private-servers';
          faqData.expansionFaq = 'Vanilla';
          faqData.result[0].question = 'What is a Vanilla WoW private server?';
          faqData.result[0].answer = 'Vanilla WoW private servers are servers that are based on the original World of Warcraft game released in 2004. They are one of the most popular private servers and overall one of the most played private servers.';
          faqData.result[1].question = 'What is the best Vanilla WoW private server?';
          faqData.result[1].answer = 'You can find a list of the best Vanilla WoW private servers on our website. Its a subjective question and depends on what you are looking for in a private server. Because of that, we have made it easy for you as a player to find the best Vanilla server for you.';
          faqData.result[2].question = 'Why are Classic Private Servers among the most popular?';
          faqData.result[2].answer = 'A lot of play have been playing World of Warcraft for a long time and have a lot of nostalgia for the original game. It can remind them of good memories and great experiences from back then. In this case, Classic private servers are a great way to relive the classic feeling of the game and experience it again.';
          faqData.result[3].question = 'What is the difference between Vanilla WoW and Classic WoW?';
          faqData.result[3].answer = 'When you are searching for a Vanilla WoW Private Server, you might see both the word Vanilla and the word Classic being used. To clarify the difference, it&apos;s the same thing. Classic Private Servers are the original era with slower leveling, while Vanilla Private Servers have a little quicker leveling progression, along with other minor game changes. It is however barely noticeable, and therefore we recommend that you consider Vanilla Private Servers and Classic Private Servers as the same thing.';
          break;
        case Expansion.TBC:
          internalUrl = '/wow/private-servers/tbc-private-servers';
          faqData.expansionFaq = 'TBC';
          faqData.result[0].question = 'How do I get started playing on a TBC Private Server?';
          faqData.result[0].answer = 'To start playing on a TBC Private Server, you first need to find a server that you would like to log into. You can find that through our list on this TBC page. The next step is to download the game client of TBC. You can find that on our TBC client page located here: https://zremax:com/blog/tbc-2-4-3-client-download-wow-burning-crusade-client - After downloading, you need to configure the realmlist.wtf to the server you would like to play on, and then you are ready to play on a TBC Private Server!';
          faqData.result[1].question = 'Are there many TBC Private Servers around in 2024?';
          faqData.result[1].answer = 'There are a few TBC servers available in 2024, as you can also see through the list on this page. It is however not the expansion with the most private servers around, but there should still be enough for you to find a TBC server that you like and can call your new home.';
          faqData.result[2].question = 'Should I play on a blizzlike TBC server, or a custom TBC server?';
          faqData.result[2].answer = 'It is most common to be playing on a blizzlike TBC Private Server as servers that offers this gameplay tends to have a higher population, than for example custom TBC servers. It is however optional, and you should play on the server type that you enjoy the most.';
          faqData.result[3].question = 'How do you level up the quickest in TBC?';
          faqData.result[3].answer = 'In case you have found a TBC Private Server to play on, you will most likely be looking into leveling up as fast as possible, so that you can experience the end game content. For this reason, we have created a TBC Leveling guide that you can follow here: https://zremax.com/blog/wow-tbc-leveling-guide - giving you tips and tricks on what zones to level in, and what quests to complete.';
          break;
        case Expansion.WOTLK:
          internalUrl = '/wow/private-servers/wotlk-private-servers';
          faqData.expansionFaq = 'Wotlk';
          faqData.result[0].question = 'Are Wotlk Private Servers popular?';
          faqData.result[0].answer = 'Yes, Wotlk Private Servers are extremely popular. It is the expansion with the most amount of players ingame. For example, Warmane, which is a Wotlk server, has a lot of players ingame and is one of the most popular servers to play on, and most of their realms are Wotlk based.';
          faqData.result[1].question = 'Are Wotlk Private Servers worth playing on?';
          faqData.result[1].answer = 'Our recommendation is to play on Wotlk Private Servers, if you dont have a specific reason to play on another expansion of World of Warcraft. Wotlk servers are the most popular ones, and therefore you will always be able to explore content with other players. Additionally, it will be easy to find new gaming friends through dungeons and raids, because guilds are always searching for new people.';
          faqData.result[2].question = 'What game client do I need to play on a Wrath of the Lich King Private Server?';
          faqData.result[2].answer = 'In order to get ingame, you need to have a 3.3.5 WoW Client. It is a requirement, as you cannot play on a Wotlk server without it. We have created an article with download links to this particular game client version. You can download that here: https://zremax.com/blog/wotlk-3-3-5-client-download-wrath-of-the-lich-king-client/';
          faqData.result[3].question = 'Should I play on a Blizzlike Wotlk server, or a Custom Wotlk server?';
          faqData.result[3].answer = 'The most active Wotlk servers are blizzlike based. So, if you want to play on a high populated server, we recommend going for a Blizzlike Wotlk Private server. However, if you enjoy custom content, we advise trying one of the custom Wotlk servers around as well. The custom servers are not as popular, but may bring you a lot of fun while gaming as well.';
          break;
        case Expansion.Cataclysm:
          internalUrl = '/wow/private-servers/cataclysm-private-servers';
          faqData.expansionFaq = 'Cataclysm';
          faqData.result[0].question = 'Are there any Cataclysm Private Servers around?';
          faqData.result[0].answer = 'Yes, absolutely! Through our list on this page, we have listed every Cataclysm Private Server that are available to play on. The purpose is to make it quick and simple for you to discover your next favorite Cata server. Some of them are blizzlike, while others are custom, and then you can decide which one you would like to play on.';
          faqData.result[1].question = 'How do I connect to a Cataclysm Private Server?';
          faqData.result[1].answer = 'In order to connect to a Cataclysm server, you need to get a valid WoW Client that supports patch 4.3.4, which is the patch that the the Cata servers are using. We have created an article that provides you with the download links, find the article here: https://zremax.com/blog/wow-cataclysm-4-3-4-client-download';
          faqData.result[2].question = 'Is Cataclysm a popular expansion?';
          faqData.result[2].answer = 'Cataclysm has a lot of different opinions. We hear a lot of feedback from players that absolutely love the expansion, while there are also people stating that Cataclysm was the downhill of World of Warcraft. But overall, its still a pretty popular expansion, that a lot of gamers are interested in.';
          faqData.result[3].question = 'Is it worth playing on a Cataclysm Private Server?';
          faqData.result[3].answer = 'If you are into the Cataclysm expansion, then we highly recommend playing on one of the top Cataclysm servers. We believe you can have a lot of fun, if youre into the expansion. There are a few good Cataclysm servers that you can find through our list. Happy gaming!';
          break;
        case Expansion.MOP:
          internalUrl = '/wow/private-servers/mop-private-servers';
          faqData.expansionFaq = 'MoP';
          faqData.result[0].question = 'Are there many MoP private servers around?';
          faqData.result[0].answer = 'In the past, MoP wasnt a very popular expansion, and there were not a lot of servers offering the expansion. However, in the recent years, a lot of new Mop servers have appeared, and the playerbase has also increased heavily. We have listed the best MoP servers on this page, so that you can find your next favorite MoP server.';
          faqData.result[1].question = 'How do I get started playing on Mop Private Servers?';
          faqData.result[1].answer = 'First, you need to find a specific server you would like to play on. Additionally, you need to download a Mop client that runs on patch 5.4.8, which is what the Mop servers support. You can get that here: https://zremax.com/blog/5-4-8-mop-client';
          faqData.result[2].question = 'Are Blizzlike MoP servers or custom MoP servers the most popular?';
          faqData.result[2].answer = 'In general, the most popular MoP servers are definitely blizzlike based. For example, this is Stormforge by Tauri and Atlantiss, Evermoon by Tauri, and Helios-WoW by Twinstar. They are all the largest Mop servers and are all blizzlike based.';
          faqData.result[3].question = 'Mists of Pandaria servers and patches?';
          faqData.result[3].answer = 'Mists of Pandaria is a game made by World of Warcraft and the patches go from 5.0 to 5.4.8. Most of the Private Servers for this expansion are running on patch 5.4.8. However, a lot of them start as progressive servers, meaning that you will start with the 5.0 content, while later content will be unlocked at a later date.';
          break;
        case Expansion.WOD:
          internalUrl = '/wow/private-servers/wod-private-servers';
          faqData.expansionFaq = 'WoD';
          faqData.result[0].question = 'Is WoD a popular expansion?';
          faqData.result[0].answer = 'World of Draenor is not one of the popular expansions. In fact, it is the least liked expansion, and made a lot of players quit World of Warcraft. Because of that, there are not many gamers playing on WoD servers either. Currently, only Firestorm has an active WoD server available to play on.';
          faqData.result[1].question = 'Is it possible to play on a WoD private server?';
          faqData.result[1].answer = 'Yes it is possible to explore WoD again, if you liked the expansion. You can however only play WoD through Firestorm, as thats the only official server offering this expansion.';
          faqData.result[2].question = 'Do I need a WoD client to play on a WoD private server?';
          faqData.result[2].answer = 'It is not possible to get ingame without a Game client, and this particular game client must match the version of the server you are trying to get into.';
          faqData.result[3].question = 'What is the max level of WoD?';
          faqData.result[3].answer = 'The max level of WoD is level 100, where you get to explore the new Draenor continent. Additionally, you will be able to unlock Artifact weapons, and the Garrosh Hellscream raid.';
          break;
        case Expansion.Legion:
          internalUrl = '/wow/private-servers/legion-private-servers';
          faqData.expansionFaq = 'Legion';
          faqData.result[0].question = 'Are all Legion Private Servers free?';
          faqData.result[0].answer = 'Yes, all Legion servers are free to play on. Thats one of the benefits of playing on a Private Server. However, keep in mind that most of the servers has a store where you can buy items and upgrade your characters, if you donate.';
          faqData.result[1].question = 'Are there many Legion private servers around?';
          faqData.result[1].answer = 'In general, Legion servers have increased over the past years. The reason is that more players are interested in playing on this expansion, which has made the server owners create more Legion servers. You can find all the Legion servers on our list.';
          faqData.result[2].question = 'What gameplay can Legion offer?';
          faqData.result[2].answer = 'Legion has a max level 110, and you can explore all the Broken Isles. You can also play on the new Demon Hunter class, which was introduced in this expansion, as well as play on new raids.';
          faqData.result[3].question = 'Can I get banned for playing on a Legion Private Server?';
          faqData.result[3].answer = 'In general, you will not get banned. But make sure not to mention that you are playing on a Private Server on retail, as you could risk getting punished.';
          break;
        case Expansion.BFA:
          internalUrl = '/wow/private-servers/bfa-private-servers';
          faqData.expansionFaq = 'BFA';
          faqData.result[0].question = 'What is the max level of BFA?';
          faqData.result[0].answer = 'The max level of BFA is level 120, and with the end-game you get to explore the new Zandalar and Kul Tiras continents. It is one of the newest expansions made by Blizzard. If you are into new and updated content, BFA may be a good fit for you!';
          faqData.result[1].question = 'When did BFA release?';
          faqData.result[1].answer = 'Battle for Azeroth released the 14th of August 2018, so it has been around for a few years, but is still one of the newest expansions made by Blizzard. It was a relatively high success.';
          faqData.result[2].question = 'Are there BFA Private Servers to play on?';
          faqData.result[2].answer = 'Yes, you can play on quite a few BFA servers because a lot of players are interested in this expansion. You can see all of the servers on our page.';
          faqData.result[3].question = 'Are BFA servers free to play on?';
          faqData.result[3].answer = 'BFA servers for WoW are free to play on because it is a Private Server. If you want to play BFA, the only way to do it through a fanserver, as retail has already progressed to Dragonflight.';
          break;
        case Expansion.Shadowlands:
          internalUrl = '/wow/private-servers/shadowlands-private-servers';
          faqData.expansionFaq = 'Shadowlands';
          faqData.result[0].question = 'Where can I play on a Shadowlands Private Server?';
          faqData.result[0].answer = 'You can play on a server for Shadowlands on our list. We have a few different servers to play on, with the most popular one being Firestorm with their realm: Oribos.';
          faqData.result[1].question = 'Is it free to play on a private server for Shadowlands?';
          faqData.result[1].answer = 'Yes, private servers are free to play on, and this includes the Shadowlands servers as well. This is one of the perks of playing on a private server.';
          faqData.result[2].question = 'Is Shadowlands a hard patch to play on?';
          faqData.result[2].answer = 'Shadowlands is one of the easiest expansions, as it is not considered very hardcore. Because of that, it is a good expansion for new players who want to get into MMORPG and World of Warcraft.';
          faqData.result[3].question = 'How can I get started on a Shadowlands server?';
          faqData.result[3].answer = 'The first step is to find a server on our list. Next step is to download a Shadowlands client, which you can get from here: https://zremax.com/blog/shadowlands-client-9-0-x-client-for-shadowlands';
          break;
        case Expansion.Dragonflight:
          internalUrl = '/wow/private-servers/dragonflight-private-servers';
          faqData.expansionFaq = 'Dragonflight';
          faqData.result[0].question = 'Is Dragonflight a new expansion?';
          faqData.result[0].answer = 'Dragonflight is the newest expansion for World of Warcraft, which is made by Blizzard. A lot of players are excited for this version, and are looking forward to playing it on a fanserver.';
          faqData.result[1].question = 'What is the best Dragonflight Private server?';
          faqData.result[1].answer = 'Currently, there are no Dragonflight servers, besides from retail. So if you would like to play Dragonflight, you must either wait until a fanserver comes out, or play directly on retail.';
          faqData.result[2].question = 'What is the max level of Dragonflight?';
          faqData.result[2].answer = 'The max level of Dragonflight is 60, and you can explore the content that was introduced in this expansion. Blizzard has worked a lot on it to solve many of the complains from their past expansions.';
          faqData.result[3].question = 'Is this page updated with new Dragonflight news?';
          faqData.result[3].answer = 'Absolutely! We are regularly updating this page with new updates for the Dragonflight expansion. So make sure to check back in the future.';
          break;
      }
    } else if (query.type) {
      switch (query.type) {
        case 'PVP':
          internalUrl = '/wow/private-servers/pvp-private-servers';
          break;
        case 'PVE':
          internalUrl = '/wow/private-servers/pve-private-servers';
          break;
        case 'RP':
          internalUrl = '/wow/private-servers/rp-private-servers';
          break;
        case 'Custom':
          internalUrl = '/custom-private-servers';
          break;
      }
    }
  }
  if (!faqData.expansionFaq) {
    internalUrl = '/wow/private-servers';
    faqData.expansionFaq = 'WoW';
    faqData.result[0].question = 'What is the Best WoW Private Server in 2024?';
    faqData.result[0].answer = 'The Best WoW Private Server in 2024 can get found through our list at Zremax. The reason is that we list all the good private servers for World of Warcraft. As a result, you can easily pick the server that fits your requirements the most. For instance, you can prioritize a server with a high population, or a specific realm-type such as a PvP realm. Through our list, you can even select your favorite realm features, such as a blizzlike or a custom server.';
    faqData.result[1].question = 'What should I know about WoW Private Servers?';
    faqData.result[1].answer = 'A WoW Private Server is a way for players to play the older versions of the game. For example, if you want to play Cataclysm, you can find that through a WoW Private Server. Private Servers for World of Warcraft were extremely relevant before Blizzard started introducing classic themselves through retail. However, even though Blizzard has now reached WotLK through classic, WoW Private Servers are still extremely relevant. It is very beneficial if you for example want to play with customized game-play or increased XP rates. A lot of these servers tend to offer a blizzlike plus experience, meaning higher XP rates, and sometimes custom features which can make the game feel more innovative.';
    faqData.result[2].question = 'Is it safe to play on WoW Private Servers?';
    faqData.result[2].answer = 'Whether playing on private servers for World of Warcraft is completely legal or not, is a very relevant topic. As a general rule, make sure you play on a server that takes your data serious, or at least use a unique password. However, to clear up any confusion, it is not illegal to be playing on a fanserver.';
    faqData.result[3].question = 'What is a World of Warcraft Top List?';
    faqData.result[3].answer = 'A World of Warcraft Toplist is a page, where you have a complete overview of all the available private servers to play on. The benefits of using a World of Warcraft Top 100 Server List is that you have all the servers at one place. Instead of having to go to many different websites, all the wow servers are collected in one place. This makes it easy and convinient for WoW Players, as it can be time consuming to find the right WoW server to play on.';
  }
  return {
    props: {
      internal: !!query.internal,
      internalUrl,
      expansion: query.expansion,
      realmType: query.type,
      bannersProp: banners,
      serversProp: data.servers,
      totalPagesProp: data.totalPages,
      currentPageProp: data.currentPage,
      queryProp: data.query,
      key: JSON.stringify(query),
      host: process.env.NEXTAUTH_URL,
      faqData
    }
  };
}, []);
export default _withSuperJSONPage(Page);