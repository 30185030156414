import { Icon } from '@iconify/react'
import { Expansion, Servers } from '@prisma/client'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import StarRatings from '../StarRatings'

interface IProps {
  server: (Servers & {
    realmFeatures: string[]
    isFeatured: boolean
    averageRating: number
  })
}

const Server = ({ server }: IProps) => {
  const expansion = (expansion: Expansion | null) => {
    let imageSrc = ''
    let imageAlt = ''

    switch (expansion) {
      case Expansion.Vanilla:
        imageSrc = '/images/expansions/vanilla.png'
        imageAlt = 'Vanilla'
        break
      case Expansion.TBC:
        imageSrc = '/images/expansions/tbc.png'
        imageAlt = 'The Burning Crusade'
        break
      case Expansion.WOTLK:
        imageSrc = '/images/expansions/wotlk.png'
        imageAlt = 'Wrath of the Lich King'
        break
      case Expansion.Cataclysm:
        imageSrc = '/images/expansions/cataclysm.png'
        imageAlt = 'Cataclysm'
        break
      case Expansion.MOP:
        imageSrc = '/images/expansions/mop.png'
        imageAlt = 'Mists of Pandaria'
        break
      case Expansion.WOD:
        imageSrc = '/images/expansions/wod.png'
        imageAlt = 'Warlords of Draenor'
        break
      case Expansion.Legion:
        imageSrc = '/images/expansions/legion.png'
        imageAlt = 'Legion'
        break
      case Expansion.BFA:
        imageSrc = '/images/expansions/bfa.png'
        imageAlt = 'Battle for Azeroth'
        break
      case Expansion.Shadowlands:
        imageSrc = '/images/expansions/shadowlands.png'
        imageAlt = 'Shadowlands'
        break
      case Expansion.Dragonflight:
        imageSrc = '/images/expansions/dragonflight.png'
        imageAlt = 'Dragonflight'
        break
      default:
        return null
    }

    return <Image width={140} height={60} className="" src={require(`../../../public${imageSrc}`)} alt={imageAlt} placeholder="blur" />
  }

  const getAdjustedServerExpansion = () => {
    return server.expansion?.toLocaleLowerCase() + '-private-servers/'
  }

  return <>
    <div className={'server-card' + (server.isFeatured ? ' server-card-featured' : '')}
      data-server-id={server.id}
    >
      <Row>
        <Col md={3}>
          <div className="server-card_image">
            <div className='server-card_image_wrapper'>
              <Link href={{
                pathname: `/wow/private-servers/${getAdjustedServerExpansion()}${server.slugTitle}`
              }}>

                <Image
                    className="addon-card_image_wrapper_image"
                    width={250} height={250}
                    src={server.imageUrl || '/images/logo.svg'}
                    alt={server.slugTitle!}
                    layout='responsive'
                  />

              </Link>
            </div>
          </div>
        </Col>
        <Col md={9}>
          <div className="server-card_content">
            <div className="server-card_content_header">
              <div className="server-card_content_header_title">
                <div className="server-card_content_header_title_title">
                  <h2>
                    <Link href={{
                      pathname: `/wow/private-servers/${getAdjustedServerExpansion()}${server.slugTitle}`
                    }}>

                      <span className='server-card_content_header_title_title_text'>
                        {server.title}
                        {server.isFeatured && (
                          <i className="server-card_content_header_title_icon featured-icon-listing">
                            <Image width={29} height={29} src={require('../../../public/images/verified.png')} alt="Verified WoW Private Server" />
                          </i>
                        )}
                      </span>
                      <span className="server-card_content_header_title_expansion">
                        {expansion(server.expansion)}
                      </span>

                    </Link>
                  </h2>
                </div>
                <div className="server-card_content_details">
                  {server.averageRating > 0 && (
                    <div className="server-card_content_details_stars">
                      <StarRatings rating={server.averageRating} />
                    </div>
                  )}
                  <div className="server-card_content_details_info">
                    <div className="server-card_content_details_population">
                      <Icon icon="bi:people-fill" className="server-card_content_details_population_icon" />
                      <span>
                        <span className="server-card_content_details_info-bold">Population: </span>{server.population}
                      </span>
                    </div>
                    <div>
                      <span className="server-card_content_details_info-bold">Language: </span>{server.realmLanguage}
                    </div>
                  </div>
                </div>
                <div className="server-card_content_features">
                  <span className="server-card_content_features_feature">{server.expansion}</span>
                  <span className="server-card_content_features_feature">{server.realmStatus}</span>
                  {server.realmFeatures && server.realmFeatures.map((feature: string, index: number) => (
                    <span className="server-card_content_features_feature" key={`${feature}_${index}`}>{feature}</span>
                  ))}
                  { /* @ts-ignore */}
                  {server.realmType && server.realmType.map((feature: string, index: number) => (
                    <span className="server-card_content_features_feature" key={`${feature}_${index}`}>{feature}</span>
                  ))}
                  <span className="server-card_content_features_feature">Rates: {server.realmRates}</span>
                </div>
              </div>
              <div className="server-card_content_header_expansion">
                {expansion(server.expansion)}
              </div>
            </div>
            <hr className="server-card-content_divider" />
            <div className="server-card_content_description">
              {server.description}
            </div>

            <div className="server-card_content_action">
              <Link
                href={{
                  pathname: `/wow/private-servers/${getAdjustedServerExpansion()}${server.slugTitle}`
                }}
                className="btn btn-primary zx-btn-blue">
                Join Server
              </Link>
              <span>Launched in {server.onlineSince}</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </>
}

export default Server
