import { Servers } from '@prisma/client'
import React, { useEffect, useRef, useState } from 'react'
import Server from './Server'
import api from '@/api/index'

interface IProps {
  servers: (Servers & {
    realmFeatures: string[]
    isFeatured: boolean
    averageRating: number
  })[]
}

/**
 * Renders a list of servers with their details and features.
 * Also tracks which servers are currently in view and sends impressions to the server API.
 *
 * @param {IProps} servers - An array of server objects with their details and features.
 * @returns {JSX.Element} - A JSX element that renders the list of servers and a message if no servers are found.
 */
const ServerListing = ({ servers }: IProps) => {
  const observer = useRef<IntersectionObserver | null>(null)
  const [serverIds, setServerIds] = useState<number[]>([])

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const serverId = Number(entry.target.getAttribute('data-server-id'))
          setServerIds((prevServerIds) => [...prevServerIds, serverId])
          observer.current?.unobserve(entry.target)
        }
      })
    }, { threshold: 1.0 })

    const serverElements = document.querySelectorAll('.server-card')
    serverElements.forEach((element) => {
      observer.current?.observe(element)
    })

    return () => {
      observer.current?.disconnect()
    }
  }, [])

  useEffect(() => {
    let intervalId: number | null = null
    if (serverIds.length > 0) {
      intervalId = window.setInterval(() => {
        api.server.setServerImpressions(serverIds)
        setServerIds([])
      }, 500)
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [serverIds])

  return (
    <>
    <div>
      { servers.map((server) => (
        <Server key={server.id} server={server} />
      )) }
    </div>
    { servers.length === 0 && (
      <div className="server-no-results">
        <h2>No Servers found!</h2>
        <p>There were no servers found with the given filters.</p>
        <br/><i>Perhaps</i> you want to try one of these servers instead?<br/>
        <a className="mt-2 mb-2 btn btn-primary zx-btn-blue" href="/wow/private-servers">Find WoW Private Servers</a>
      </div>
    ) }
    </>
  )
}

export default ServerListing
