import React from 'react'
import Image from 'next/image'
import { Row, Col } from 'react-bootstrap'
import { AdBanners } from '@prisma/client'

// const imagePath = `${folder}/${uuidv4()}.${extension}`
// <Image layout='fill' className="ad-basnner-img" src=`${imagePath} + 'whitemane.png'`/>

interface IProps {
  banners: AdBanners[]
}

const HomeAd = ({ banners } : IProps) => {
  return (
  <>
    <div className="ad-layout">
      {banners.length > 0 &&
    (banners.map((banner) => (
      <Row className="" key={banner?.id}>
        <Col className="d-flex justify-content-center mb-4" md={12}>
          {/* ToDo: Consider if we should force the images to a certain size (larger) to match the container width of 1330 (bootstrap 12 columns width) */ }
          <div className="banner-wrapper">
            <a href={banner?.websiteUrl || ''} target="_blank" rel="noopener noreferrer">
              <Image layout='fill' className="ad-banner-img" src={`/images/ads/${banner?.imagePath}`} alt={banner?.websiteUrl || ''} />
              </a>
          </div>
        </Col>
      </Row>
    )))}
    </div>
  </>
  )
}

export default HomeAd
