import Link from 'next/link'
import React from 'react'
import { Row, Col } from 'react-bootstrap'

const TBCExpansion = () => (
  <>
    <div className="home-detailed-text-section">
      <Row className="d-flex">
        <Col className="mb-4">
          <h2 className="zx-text-color-yellow fs-3 mb-2" >Discover TBC Burning Crusade Private Servers</h2>
          <div className="detailed-description">
            <p>Do you want to experience Karazhan and Black Temple again? By playing on a TBC Private Server, you can make it happen. Currently, there are quite a few popular Burning Crusade Private Servers to choose between. We have made an updated and high-quality list of available 2.4.3 TBC servers. Our TBC list includes both newly/old released servers, high/low populated servers, as well as blizzlike and custom servers, and more. You can however filter through our list and add your server preferences (such as population, rates, realm-type, etc.) to only get the servers that match your style.
              <br/><br/> Alternatively, if you prefer, you can of course also simply scroll through all the Burning Crusade WoW Servers we show until you find one that you like. In general, each TBC Private Server is unique and there will always be pros and cons. Down below, we will however let you know of the ones we recommend you if you lack inspiration.</p>
          </div>
        </Col>
      </Row>

      <Row className="d-flex">
        <Col className="mb-4">
          <h2 className="zx-text-color-yellow fs-3 mb-2" >Play on the best TBC Private Servers</h2>
          <div className="detailed-description">
          <p>
            The year is 2022. Luckily, there are several burning crusade wow private servers that have a high standard, quality-wise.<br/>
            Have you asked or even wondered? “What is the best TBC Private Server available?” If so, I can assure you that you’re not alone. As you can see on this page, we provide a list of all the TBC WoW Servers available to play on. What the best TBC server is for you, is however subjective. However, we see a clear pattern in terms of population, and therefore we at Zremax can give you some guidelines for Burning Crusade Fan servers.<br/>
            Currently, in 2022, there are two major TBC Private Servers around, which both dominate the TBC WoW Scene. The first TBC Server that we refer to, is Atlantiss – Karazhan. Atlantiss – Karazhan TBC server released the 25th of July 2022, so it is still rather fresh, and not fully progressed. The realm is having a stable population between 3000-5000 players online and is one of the most populated TBC servers. The realm type from Atlantiss is PvP and runs on 1-2X rates, with only a cosmetic shop (no-pay-2-win). If you’re searching for a TBC server that matches these criteria, then we can certainly call Atlantiss’ Karazhan the Best TBC Private Server available in 2022.<br/>
            If you´re however looking for a TBC WoW Server with higher rates, but yet still a high population, then we can recommend considering Endless.gg.<br/>
            Endless.gg’s realm, Sunstrider is running on 5X rates, and no P2W either. Sunstrider, from Endless, however released a little earlier than Atlantiss’ Karazhan realm. Sunstrider (Endless), released the 28th of February 2022. We have checked their TBC WoW Server, and it however still appears to be healthy and active.<br/>
            In case neither of these TBC Private Servers matches your criteria, don’t worry. You can easily scroll through our full list of all the TBC servers and hopefully find one that you like.
          </p>
          <p>When you have found the best TBC Server, make sure to have the right <a href="/wow/addons/tbc-addons">TBC WoW Addons</a> set up for this expansion as well. It truly helps you to become a better player while fighting against other players in PvP, and against bosses in PvE.</p>
          </div>
        </Col>
      </Row>

      <Row className="d-flex">
        <Col className="mb-4">
          <h2 className="zx-text-color-yellow fs-3 mb-2" >What is a TBC WoW Private Server? </h2>
          <div className="detailed-description">
            <p>
            A TBC Private Server is a fan server of Blizzard’s original game; World of Warcraft, from when Blizzard was emulating on TBC, 2.4.3. Many people miss TBC from retail and would like to go back to playing it. By playing on a WoW TBC Private Server, that is possible. This way, you can level from 1-70, you can do Karazhan, Black Temple, obtain T6 gear, and get all your good old TBC memories back. A lot of WoW Players believe that TBC was one of the best WoW expansions. Because of that, it’s also natural that people want to be playing it again, like good old times. Joining a TBC Private Server is quite simple. It’s mostly about just finding a TBC server that fits you, which can get done through our compiled list of TBC Servers. Afterward, you will be registering on the specific server you have chosen from our list of TBC servers, and noting down their realmlist. After doing so, you are ready for the last step: getting a <Link className="a-underline-none" href="/blog/tbc-2-4-3-client-download-wow-burning-crusade-client">TBC 2.4.3 Client</Link>, so you can actually play on the TBC WoW Server you have chosen, which we have an article about too. After downloading and changing the realmlist, you are ready to get back to TBC and experience The Burning Crusade expansion of World of Warcraft, once again!
            </p>
          </div>
        </Col>
      </Row>

      <Row className="d-flex">
        <Col className="mb-4">
          <h2 className="zx-text-color-yellow fs-3 mb-2" >Conclusion</h2>
          <div className="detailed-description">
          <p>As you have found out through our list of TBC Servers, there are several different TBC Private Servers to choose between. Through our filters, you can find your favorite one. At this page, we have also suggested a couple of popular Burning Crusade WoW Servers in case you want to play on the Best TBC Private Servers.</p>
          </div>
        </Col>
      </Row>
    </div>
  </>
)

export default TBCExpansion
