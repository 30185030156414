import React from 'react'
import GeneralExpansion from './expansions/description/GeneralExpansion'
import { Expansion } from '@prisma/client'
import VanillaExpansion from './expansions/description/VanillaExpansion'
import TBCExpansion from './expansions/description/TBCExpansion'
import WOTLKExpansion from './expansions/description/WOTLKExpansion'
import CataExpansion from './expansions/description/CataExpansion'
import MOPExpansion from './expansions/description/MOPExpansion'
import LegionExpansion from './expansions/description/LegionExpansion'
import WODExpansion from './expansions/description/WODExpansion'
import BFAExpansion from './expansions/description/BFAExpansion'
import ShadowlandsExpansion from './expansions/description/ShadowlandsExpansion'
import DragonflightExpansion from './expansions/description/DragonflightExpansion'

interface IProps {
  expansion: string
}

const HomeDetailedTextSection = ({ expansion }: IProps) => {
  const showExpansion = () => {
    switch (expansion) {
      case Expansion.Vanilla:
        return <VanillaExpansion />
      case Expansion.TBC:
        return <TBCExpansion />
      case Expansion.WOTLK:
        return <WOTLKExpansion />
      case Expansion.Cataclysm:
        return <CataExpansion />
      case Expansion.MOP:
        return <MOPExpansion />
      case Expansion.WOD:
        return <WODExpansion />
      case Expansion.Legion:
        return <LegionExpansion />
      case Expansion.BFA:
        return <BFAExpansion />
      case Expansion.Shadowlands:
        return <ShadowlandsExpansion />
      case Expansion.Dragonflight:
        return <DragonflightExpansion />
      default:
        return <GeneralExpansion />
    }
  }

  return (
    <>
      <div id='expansion-description'>
        {showExpansion()}
      </div>
    </>
  )
}

export default HomeDetailedTextSection
